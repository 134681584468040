import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

export const RoboLiveContext = createContext();

export function RoboLiveProvider({ children }) {

    return (
        <RoboLiveContext.Provider value={{

        }}>
            {children}
        </RoboLiveContext.Provider>
    );
}

RoboLiveProvider.propTypes = {
    children: PropTypes.node.isRequired,
};