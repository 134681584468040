import React, { useState, useContext, useCallback } from 'react'

import { Typography, Slider, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors as themeColors } from '@themes/config/theme-colors'
import { RgbLightIcon } from '@components/icons'
import StyledBox from '@webapp/components/blocks/component/styled-box'

import { EditorContext } from '@webapp/components/hoc/WithEditor'
import { RoboContext } from '@webapp/providers/robo-context'

const saturation = 100
const lightness = 50

const LedAvatar = styled(Avatar)(({ status }) => ({
  width: '76px',
  height: '76px',
  backgroundColor: status === 'true' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
}))

const StyledSlider = styled(Slider)(({ value }) => ({
  width: '62%',
  background: 'fff',
  borderRadius: 5,

  '& .MuiSlider-rail': {
    background: 'linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00)',
    boxShadow: '0 0 0 4px rgba(255, 255, 255, 1)',
    height: '8px',
    opacity: 1,
  },

  '& .MuiSlider-thumb': {
    width: '30px',
    height: '30px',
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    backgroundColor: `hsla(${value}, 100%, 50%, 4)`,
    opacity: 1,

    '&$focusVisible': {
      boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    },

    '&:focus, &:hover, &$active': {
      boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    },
  },

  '& .MuiSlider-thumb:hover': {
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
  },

  '& .MuiSlider-thumb:focus,  & .MuiSlider-thumb$active': {
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
  },
}))

const HueSlider = ({ value, onChange, showplayButton }) => {
  return (
    <StyledSlider
      value={value}
      onChange={onChange}
      min={0}
      max={360}
      step={1}
      aria-label="hue-slider"
      track={false}
      disabled={showplayButton}
    />
  )
}

// Converts HSLA (Hue, Saturation, Lightness, Alpha) color values to RGB (Red, Green, Blue) color values.
const hslaToRgb = (hue, saturation, lightness) => {
  // Convert saturation and lightness to the range of 0 to 1
  const s = saturation / 100
  const l = lightness / 100

  // Calculate the chroma value (colorfulness) based on lightness and saturation
  const c = (1 - Math.abs(2 * l - 1)) * s

  // Convert hue to the range of 0 to 6 (for easier calculation)
  const h60 = hue / 60

  // Calculate the intermediate value for determining the RGB components
  const x = c * (1 - Math.abs((h60 % 2) - 1))

  // Calculate the offset needed for each RGB component
  const m = l - c / 2

  // Determine the RGB values based on the hue value
  let r, g, b
  switch (Math.floor(h60)) {
    case 0:
      r = c
      g = x
      b = 0
      break
    case 1:
      r = x
      g = c
      b = 0
      break
    case 2:
      r = 0
      g = c
      b = x
      break
    case 3:
      r = 0
      g = x
      b = c
      break
    case 4:
      r = x
      g = 0
      b = c
      break
    case 5:
    default:
      r = c
      g = 0
      b = x
  }

  // Scale the RGB values to the 0-255 range and round them to integers
  return {
    red: Math.round((r + m) * 255),
    green: Math.round((g + m) * 255),
    blue: Math.round((b + m) * 255),
  }
}

const VisualRGBLightControl = ({ widgetNumber, width, height }) => {
  const [hue, setHue] = useState(0)
  const [isLedOn, setIsLedOn] = useState(true)
  const showplayButton = useContext(EditorContext)
  const { robo } = useContext(RoboContext)
  const [lastProcessedTime, setLastProcessedTime] = useState(0)

  const roboSystem = robo?.ledRGBs[`LEDRGB_${widgetNumber}`]

  const updateLedState = (newIsLedOn, newHue) => {
    const RGBvalue = hslaToRgb(newHue, saturation, lightness)

    setIsLedOn(newIsLedOn)
    roboSystem?.setActive(newIsLedOn)
    if (newIsLedOn) {
      roboSystem?.setColor(RGBvalue.red, RGBvalue.green, RGBvalue.blue)
    }
    roboSystem?.execute()
  }

  const handleHueChange = (event, newValue) => {
    const currentTime = Date.now()
    // Calculate the time elapsed since the last processed time
    const isTimeElapsed = currentTime - lastProcessedTime >= 250

    if (newValue !== hue && isTimeElapsed && isLedOn) {
      updateLedState(isLedOn, newValue)
      setLastProcessedTime(currentTime)
    }
    setHue(newValue)
  }

  const toggleLedState = () => {
    updateLedState(!isLedOn, hue)
  }

  return (
    <StyledBox
      width={width}
      height={height}
      color="#FFB71C"
      direction="row"
      paddingLeft="10px"
      paddingRight="48px"
      justify="space-between"
    >
      <Typography
        variant="x-headline5-semibold"
        color={themeColors.white['500']}
        sx={{ position: 'absolute', top: '5px', left: '91px' }}
      >
        {widgetNumber}
      </Typography>

      <LedAvatar status={isLedOn.toString()} onClick={showplayButton ? null : toggleLedState}>
        <RgbLightIcon sx={{ width: '58px', height: '58px' }} />
      </LedAvatar>

      <HueSlider value={hue} onChange={handleHueChange} showplayButton={showplayButton} />
    </StyledBox>
  )
}

export default VisualRGBLightControl
