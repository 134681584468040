import React from 'react'

import MotionVerticalSlider from '../widgets/motor-slider/motion-vertical-slider'
import MotorPositionControl from '../widgets/motor-position-control/motor-position-control'
import VisualRGBLightControl from '../widgets/visual-rgb-light/visual-rgb-light-control'
import LightSensorControl from '../widgets/light-sensor-control/light-sensor-control'
import MotionSensorControl from '../widgets/motion-sensor-control/motion-sensor-control'
import DistanceSensorControl from '../widgets/distance-sensor-control/distance-sensor-control'
import LineFollowerControl from '../widgets/line-follower-control/line-follower-control'
import JoystickControl from '../widgets/joystick-control/joystick-control'
import laughingFaceControl from '../widgets/laughing-Face-control/laughing-Face-control'
import SmilingFaceControl from '../widgets/smiling-face-control/smiling-face-control'
import LookingFaceControl from '../widgets/looking-face-control/looking-face-control'
import { getSoundWidgetList } from '@webapp/components/blocks/widgets/sound-control/sound-control'

const componentMap = {
  Motor: { component: MotionVerticalSlider, width: 1, height: 4 },
  Angle: { component: MotorPositionControl, width: 2, height: 2 },
  Light: { component: VisualRGBLightControl, width: 4, height: 1 },
  Sensor: { component: LightSensorControl, width: 1, height: 1 },
  Motion: { component: MotionSensorControl, width: 1, height: 3 },
  Distance: { component: DistanceSensorControl, width: 1, height: 3 },
  Line: { component: LineFollowerControl, width: 2, height: 2 },
  Joystick: { component: JoystickControl, width: 2, height: 2 },
  Laughing: { component: laughingFaceControl, width: 1, height: 1 },
  Smiling: { component: SmilingFaceControl, width: 1, height: 1 },
  Looking: { component: LookingFaceControl, width: 1, height: 1 },
}

const SoundWidgetList = getSoundWidgetList()

export const GenerateDynamicWidgetList = () => {
  const dynamicComponents = []
  Object.entries(componentMap).forEach(([type, componentConfig]) => {
    const count = getCountForType(type)
    const { component: Component, width, height } = componentConfig

    for (let i = 0; i < count; i++) {
      dynamicComponents.push({
        id: `${type}_${i}`,
        component: <Component widgetNumber={i + 1} />,
        width: width,
        height: height,
      })
    }
  })

  SoundWidgetList.map(soundWidgetList => {
    dynamicComponents.push(soundWidgetList)
  })

  return dynamicComponents
}

const getCountForType = type => {
  switch (type) {
    case 'Motor':
      return 6
    case 'Angle':
      return 6
    case 'Light':
      return 6
    case 'Sensor':
      return 4
    case 'Motion':
      return 4
    case 'Distance':
      return 4
    case 'Line':
      return 4
    case 'Laughing':
      return 4
    case 'Smiling':
      return 4
    case 'Looking':
      return 4
    case 'Joystick':
      return 1
    default:
      return 0
  }
}
