import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, IconButton } from '@mui/material';

import { MainBlockIcon } from '@components/icons';

const ConnectedView = () => {
    const [open, setOpen] = useState(true);

    return (
        <Dialog open={open} onClose={() => { }} slotProps={{
            backdrop: {
                sx: {
                    background: 'rgba(126, 99, 182, 0.6)'
                }
            }
        }}>
            <DialogContent sx={{ background: '#FFFFFF', minWidth: '500px', pt: 6 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="x-headline3-black" sx={{ textAlign: 'center' }}>
                            Hooray! You're <br /> connected
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            size='large'
                            sx={{
                                borderColor: '#1CA543',
                                borderWidth: '10px',
                                borderStyle: 'solid',
                                width: '128px',
                                height: '128px',
                                boxSizing: 'border-box',
                                ml: 3,
                            }}>
                            <MainBlockIcon sx={{
                                width: '90px',
                                height: '90px',
                            }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center', pl: 4, pb: 6, pt: 2 }}>
                <Button
                    variant='contained'
                    onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConnectedView;