import React from 'react'
import { Router } from '@reach/router'

import { PrivateRoute } from '@lib/route'

import Layout from '@webapp/layouts/layout'
import Seo from '@components/seo'

import Dashboard from '@webapp/pages/dashboard'
import Projects from '@webapp/pages/projects'
import Tutorials from '@webapp/pages/tutorials'
import Profile from '@webapp/pages/profile'

import RoboLive from '@webapp/pages/robo-live'
import RoboCode from '@webapp/pages/robo-code'
const WebAppPage = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: 'WebApp' }} />

      <Router basepath="/webapp" style={{ height: '100%' }}>
        <PrivateRoute path="/" component={Dashboard} />
        <PrivateRoute path="/projects" component={Projects} />
        <PrivateRoute path="/tutorials" component={Tutorials} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/live" component={RoboLive} />
        <PrivateRoute path="/code" component={RoboCode} />
      </Router>
    </Layout>
  )
}

export default WebAppPage;
