import React, { useState, useCallback } from 'react';

import 'reactflow/dist/style.css';

import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
} from 'reactflow';


import CustomEdge from './elements/custom-edge';
import CustomNode from './elements/custom-node';
import CustomConnectionLine from './elements/custom-connection-line';
import CustomControls from './elements/custom-controls';

import { RoboCodeProvider } from './providers/robo-code-context';

const defaultEdgeOptions = {
  // animated: true,
  style: {
    stroke: '#5A418B',
    strokeWidth: 4,
  },
  arrowHeadType: 'arrowclosed',
  type: 'custom',
};

const connectionLineStyle = {
  stroke: '#5A418B',
  strokeWidth: 5,
};

const edgeTypes = {
  custom: CustomEdge,
};

const nodeTypes = {
  custom: CustomNode,
};

const RoboCodeEditor = ({ initialNodes, initialEdges }) => {

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback((changes) => setNodes((nodes) => applyNodeChanges(changes, nodes)), []);

  const onEdgesChange = useCallback((changes) => setEdges((edges) => applyEdgeChanges(changes, edges)), []);

  /**
   * Handles the click event on an edge and removes it from the edges array.
   * @param {Object} event - The click event object.
   * @param {Object} edge - The edge object to be removed.
   */
  const onEdgeClick = useCallback((event, edge) => {
    event.stopPropagation();
    const id = edge.id;
    setEdges((edges => edges.filter((edge) => edge.id !== id)))
  }, [nodes, edges]);

  /**
   * Adds a new edge to the list of edges.
   *
   * @param {Object} params - The parameters for the new edge.
   */
  const onConnect = useCallback((params) => setEdges((edges) => {
    return addEdge(params, edges)
  }), []);

  /**
   * Callback function that updates the edges state when nodes are deleted.
   * @callback onNodesDelete
   * @param {Array} deleted - The array of deleted nodes.
   * @returns {void}
   */
  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  return (
    <RoboCodeProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}

        defaultEdgeOptions={defaultEdgeOptions}

        connectionLineStyle={connectionLineStyle}

        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}

        connectionLineComponent={CustomConnectionLine}

        fitView

        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodesDelete={onNodesDelete}

        onEdgeClick={onEdgeClick}

        // proOptions={{ hideAttribution: true }}

        style={{
          backgroundColor: '#FFE9E9',
        }}
      >
        <CustomControls />
      </ReactFlow>
    </RoboCodeProvider>
  );
};

export default RoboCodeEditor;
