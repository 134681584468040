import React, { useState, useContext, useEffect } from 'react'

import CircularSlider from '@fseehawer/react-circular-slider'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors as themeColors } from '@themes/config/theme-colors'
import { CircularSliderIcon } from '@components/icons'
import StyledBox from '@webapp/components/blocks/component/styled-box'

import { EditorContext } from '@webapp/components/hoc/WithEditor'
import { RoboContext } from '@webapp/providers/robo-context'

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '8px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}))

const MotorPositionControl = ({ widgetNumber, w, h }) => {
  const [value, setValue] = useState(0)
  const { robo } = useContext(RoboContext)
  const [isDragging, setIsDragging] = useState(false)

  const showplayButton = useContext(EditorContext)
  const MOTOR = robo?.motors[`MOTOR_${widgetNumber}`]

  const radius = 200 / 2
  const strokeWidth = 4

  const angle = isDragging ? value : 0
  const normalizedRadius = radius - strokeWidth * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (angle / 360) * circumference

  useEffect(() => {
    if (!isDragging && value != 0) {
      const postiveValue = Math.abs(value)
      const direction = value < 0 ? 1 : 0
      MOTOR?.angle(postiveValue, direction)
    }
  }, [isDragging, value])

  return (
    <StyledBox width={w} height={h} color="#156BFB">
      {!!widgetNumber && <StyledTypography variant="x-lead-semibold">{widgetNumber}</StyledTypography>}

      <CircularSlider
        min={-360}
        max={360}
        value={value}
        hideKnob={true}
        hideLabelValue={true}
        progressSize={0}
        trackSize={0}
        preventDefault
        direction={1}
        knobPosition={'top'}
        initialValue={0}
        continuous={{
          enabled: true,
          clicks: 120,
          interval: 3,
        }}
        onChange={value => {
          setValue(value)
          console.log(value)
        }}
        isDragging={value => setIsDragging(value)}
        width={radius * 2}
        trackDraggable={!showplayButton}
        knobDraggable={!showplayButton}
      />

      <CircularSliderIcon
        sx={{
          width: '100%',
          height: '100%',
          color: 'gray',
          position: 'absolute',
          transform: `rotate(${value}deg)`,
        }}
      />

      <svg height={radius * 2} width={radius * 2} style={{ position: 'absolute', zIndex: 100, pointerEvents: 'none' }}>
        <circle
          stroke="#ffffff"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          transform={`rotate(-90, ${radius}, ${radius})`}
        />
      </svg>
    </StyledBox>
  )
}

export default MotorPositionControl
