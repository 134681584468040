import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { Responsive as ReactGridLayout } from 'react-grid-layout'

import GridLayout from '@webapp/components/editors/robo-live/grid/grid-layout'
import NoEmptySpaceModal from './modals/no-empty-space-modal'

import { GenerateDynamicWidgetList } from '@webapp/components/blocks/widgets-list-generator/widgets-list-generator'
import { GRID_COLUMNS, GRID_BREAKPOINTS } from '@webapp/components/editors/robo-live/grid/grid-config'
import { getPositionForNewElement } from '@webapp/components/editors/robo-live/grid/grid-utils'

import { RoboLiveProvider } from './providers/robo-live-context';

const dynamicComponents = GenerateDynamicWidgetList()

const getLayoutForComponent = dynamicComponents => {
    return dynamicComponents.map(components => {
        return {
            i: components.id,
            w: components.width,
            h: components.height,
            component: components.component,
        }
    })
}

const LayoutGenerate = getLayoutForComponent(dynamicComponents)
const InitialLayoutGenerate = getLayoutForComponent(dynamicComponents)

const RoboLiveEditor = ({
    containerRef,
    clickedIcon,
    columnsMap = GRID_COLUMNS,
    breakpoints = GRID_BREAKPOINTS,
    marginMap = { desktop: [16, 16], mobile: [12, 12] },
    showplayButton,
    setMotionIconsList,
    setVisualIconsList,
    setSensorIconsList,
    setSoundIconsList,
    IconcomponentLabel,
}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [layouts, setLayouts] = useState({ desktop: [] })
    const InitialLayout = { desktop: InitialLayoutGenerate }
    const [gridWidth, setGridWidth] = useState(1024)
    const [isDraggable, setIsDraggable] = useState(true)

    const [currentBreakpoint, setCurrentBreakpoint] = useState(
        ReactGridLayout.utils.getBreakpointFromWidth(breakpoints, gridWidth)
    )

    useEffect(() => {
        setIsDraggable(showplayButton)
    }, [showplayButton])

    useEffect(() => {
        updateWidth()

        const handleResize = () => updateWidth()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const updateWidth = () => {
        if (containerRef?.current) {
            const containerWidth = containerRef.current.clientWidth
            setGridWidth(containerWidth)
        }
    }

    const margin = useMemo(() => marginMap[currentBreakpoint], [marginMap, currentBreakpoint])
    const cols = useMemo(() => columnsMap[currentBreakpoint], [columnsMap, currentBreakpoint])
    const layout = useMemo(() => layouts[currentBreakpoint], [layouts, currentBreakpoint])

    useEffect(() => {
        if (clickedIcon.length) {
            const SelectedItems = InitialLayout[currentBreakpoint].filter(iconObj => iconObj.i === clickedIcon)
            const SelectedItem = SelectedItems[0]
            const newCardSize = { w: SelectedItem?.w, h: SelectedItem?.h }
            const newPosition = getPositionForNewElement(layouts[currentBreakpoint], newCardSize, cols)

            if (newPosition?.isSpaceAvailable) {
                const newItem = { ...SelectedItem, x: newPosition?.x, y: newPosition?.y }
                setLayouts(prevLayouts => ({
                    ...prevLayouts,
                    [currentBreakpoint]: [...prevLayouts[currentBreakpoint], newItem],
                }))

                if (IconcomponentLabel === 'Motion') {
                    setMotionIconsList(prevMotionIconsList => prevMotionIconsList.filter(iconObj => iconObj.id !== clickedIcon))
                }

                if (IconcomponentLabel === 'Visual') {
                    setVisualIconsList(prevVisualIconsList => prevVisualIconsList.filter(iconObj => iconObj.id !== clickedIcon))
                }

                if (IconcomponentLabel === 'Sensor') {
                    setSensorIconsList(prevSensorIconsList => prevSensorIconsList.filter(iconObj => iconObj.id !== clickedIcon))
                }

                if (IconcomponentLabel === 'Sound') {
                    setSoundIconsList(prevSoundIconsList => prevSoundIconsList.filter(iconObj => iconObj.id !== clickedIcon))
                }
            } else {
                setModalOpen(true)
            }
        }
    }, [clickedIcon])

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const onBreakpointChange = useCallback(newBreakpoint => {
        setCurrentBreakpoint(newBreakpoint)
    }, [])

    return (
        <RoboLiveProvider>
            <NoEmptySpaceModal open={modalOpen} closeModal={handleCloseModal} />

            <GridLayout
                initialLayout={LayoutGenerate}
                containerRef={containerRef}
                clickedIcon={clickedIcon}
                layouts={layouts}
                gridWidth={gridWidth}
                margin={margin}
                cols={cols}
                onBreakpointChange={onBreakpointChange}
                currentBreakpoint={currentBreakpoint}
                columnsMap={columnsMap}
                breakpoints={breakpoints}
                isDraggable={isDraggable}
            />
        </RoboLiveProvider>
    )
}

export default RoboLiveEditor;
