import React, { useContext } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { RoboCodeContext, CODE_EDITOR_MODES } from '../providers/robo-code-context'
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles'

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const CustomNodeContainer = styled('div')(({ isStartNode }) => ({
    border: isStartNode ? '4px solid #27b91b' : 'none',
    borderRadius: '50%',
}));

const StartNodeLabel = styled('div')({
    position: 'absolute',
    bottom: '-30px',
    color: 'white',
    backgroundColor: '#27b91b',
    padding: '0px 10px 3px 10px',
    borderRadius: '10px',
    left: 'calc(50% - 25px)',
    boxShadow: '0 0 10px #aaa',
});

const CustomNodeBody = styled('div')(({ isTarget, size = 1 }) => {
    let nodeDimension;    // default

    switch (size) {  // todo Update dimensions, when there will be real numbers
        case 1:
            nodeDimension = 100;
            break;
        case 2:
            nodeDimension = 150;
            break;
        case 3:
            nodeDimension = 200;
            break;
        case 4:
            nodeDimension = 250;
            break;
        case 5:
            nodeDimension = 300;
            break;
        default:
            nodeDimension = 100;
    }

    return {
        backgroundColor: 'white',
        width: nodeDimension,
        height: nodeDimension,
        border: isTarget ? '2px solid #B5135A' : '2px solid #5A418B',
        borderRadius: '50%',
        boxShadow: '0 0 10px #aaa',
        boxSizing: 'border-box',
    }
});

export default function CustomNode({ id, data }) {
    const { isStartNode = false } = data;

    const { mode } = useContext(RoboCodeContext);
    const isConnectingAllowed = mode === CODE_EDITOR_MODES.CONNECT;

    const connectionNodeId = useStore(connectionNodeIdSelector);
    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;

    return (
        <CustomNodeContainer isStartNode={isStartNode}>
            {isStartNode && (
                <StartNodeLabel>
                    <Typography variant='x-xsmall-bold'>Start</Typography>
                </StartNodeLabel>
            )}
            <CustomNodeBody isTarget={isTarget}>
                {!isConnecting && (
                    <Handle
                        className="customHandle"
                        position={Position.Right}
                        type="source"
                        style={{
                            zIndex: 1,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            opacity: 0,
                            border: 'none',
                            transform: 'none',
                            borderRadius: 0,
                            pointerEvents: isConnectingAllowed ? 'all' : 'none',
                        }}
                    />
                )}

                <Handle
                    className="customHandle"
                    position={Position.Left}
                    type="target"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        opacity: 0,
                        border: 'none',
                        transform: 'none',
                        borderRadius: 0,
                        pointerEvents: isConnectingAllowed ? 'all' : 'none',
                    }}
                />

                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#5A418B',
                        fontSize: '0.5rem',
                        fontWeight: 'bold',
                        borderRadius: '50%',
                        border: '2px dashed #671212',
                    }}
                >
                    components
                </div>
            </CustomNodeBody>
        </CustomNodeContainer>
    );
}

