import React, { useCallback } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, getStraightPath, useStore, ReactFlowState, } from 'reactflow';

const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    alert(`remove ${id}`);
};

export default function CustomEdge({
    id,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerStart,
    markerEnd,
}) {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    // Source or target node not yet rendered
    if (!sourceNode || !targetNode) {
        return null;
    }

    // Source and target node are the same. This happens when the user connects a node to itself.
    if (sourceNode.id === targetNode.id) {
        return null;
    }

    const edgesOffset = 10;
    const edgeWithNodeOverlap = 5;

    console.log('sourceNode', sourceNode);
    console.log('targetNode', targetNode);

    const sourceNodeCenter = { x: sourceNode.position.x + sourceNode.width / 2, y: sourceNode.position.y + sourceNode.height / 2 };
    const targetNodeCenter = { x: targetNode.position.x + targetNode.width / 2, y: targetNode.position.y + targetNode.height / 2 };

    const isBiDirectionEdge = useStore((s) => {
        const edgeExists = s.edges.some(
            (e) =>
                (e.source === target && e.target === source) || (e.target === source && e.source === target)
        );

        return edgeExists;
    });

    function getLineWithOffset(x1, y1, r1, x2, y2, r2, offset = 0) {
        r1 = r1 - edgeWithNodeOverlap;
        r2 = r2 - edgeWithNodeOverlap;

        // Distance between centers
        const d = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

        // Unit direction vector from O1 to O2
        const ux = (x1 - x2) / d;
        const uy = (y1 - y2) / d;

        // Perpendicular direction to the original line
        const vx = -uy;
        const vy = ux;

        // Displacing the centers along the direction perpendicular to the original line
        const displaced_x1 = x1 + offset * vx;
        const displaced_y1 = y1 + offset * vy;
        const displaced_x2 = x2 + offset * vx;
        const displaced_y2 = y2 + offset * vy;

        // Calculating the shortest line between the displaced centers
        const d_displaced = Math.sqrt((displaced_x2 - displaced_x1) ** 2 + (displaced_y2 - displaced_y1) ** 2);
        const ux_displaced = (displaced_x2 - displaced_x1) / d_displaced;
        const uy_displaced = (displaced_y2 - displaced_y1) / d_displaced;

        const l1x = displaced_x1 + r1 * ux_displaced;
        const l1y = displaced_y1 + r1 * uy_displaced;
        const l2x = displaced_x2 - r2 * ux_displaced;
        const l2y = displaced_y2 - r2 * uy_displaced;

        return { sourceX: l1x, sourceY: l1y, targetX: l2x, targetY: l2y };
    }

    function getDegree(x1, y1, x2, y2) {
        var angleRadians = Math.atan2(y2 - y1, x2 - x1);
        var angleDegrees = angleRadians * (180 / Math.PI);
        return angleDegrees;
    }

    const edgeCoordinates = getLineWithOffset(
        sourceNodeCenter.x,
        sourceNodeCenter.y,
        sourceNode.width / 2,
        targetNodeCenter.x,
        targetNodeCenter.y,
        targetNode.width / 2,
        isBiDirectionEdge ? edgesOffset : 0
    )

    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,

        ...edgeCoordinates,
    });

    const degree = getDegree(edgeCoordinates.sourceX, edgeCoordinates.sourceY, edgeCoordinates.targetX, edgeCoordinates.targetY);

    return (
        <>
            <BaseEdge id={id} path={edgePath} markerStart={markerStart} markerEnd={markerEnd} style={style} />

            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px) rotate(${degree}deg)`,
                        pointerEvents: 'all',
                        width: '9px',
                        height: '12px',
                        padding: '0',
                        lineHeight: '0',
                    }}
                    className="nodrag nopan"
                >
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L6 6L2 10" stroke="#FFE9E9" strokeWidth="5" strokeLinecap="round" />
                        <path d="M2 2L6 6L2 10" stroke="#5A418B" strokeWidth="4" strokeLinecap="round" />
                    </svg>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
