import React, { useState, useEffect, useContext } from 'react';

import { BridgeContext } from '@webapp/providers/bridge-context';
import { RoboContext } from '@webapp/providers/robo-context';

import BridgeErrorView from './bridge-error-view';
import ConnectingView from './connecting-view';
import RoboListView from './robo-list-view';
import ConnectedView from './connected-view';

const RoboConnectionManager = () => {
    const {
        roboList,
        isRoboListFetched,

        isConnected: isBridgeConnected,
        isConnecting: isBridgeConnecting,
        isError: isBridgeError,
        isConnectionLost: isBridgeConnectionLost,
        isManuallyDisconnected: isBridgeManuallyDisconnected,
    } = useContext(BridgeContext);

    const {
        handleRoboConnect,
        isConnected: isRoboConnected,
    } = useContext(RoboContext);

    // States
    const INITIAL_STATE = 'InitialState';                      // Default state on load

    const BRIDGE_CONNECTING_STATE = 'BridgeConnectingState';   // Connecting to bridge
    const BRIDGE_ERROR_STATE = 'BridgeErrorState';             // Error during bridge connection
    const BRIDGE_CONNECTION_LOST_STATE = 'BridgeConnectionLostState'; // Bridge connection lost
    const BRIDGE_MANUALLY_DISCONNECTED_STATE = 'BridgeManuallyDisconnectedState'; // Bridge manually disconnected

    const ROBO_LIST_FETCHING_STATE = 'RoboListFetchingState';  // Fetching Robo list
    const ROBO_LIST_FETCHED_STATE = 'RoboListFetchedState';    // Robo list fetched

    const ROBO_CONNECTED_STATE = 'RoboConnectedState';         // Successful Robo connection
    const ROBO_DISCONNECTED_STATE = 'RoboDisconnectedState';   // Robo disconnected

    const [currentConnectionState, setCurrentConnectionState] = useState(INITIAL_STATE);

    const [showRoboListView, setShowRoboListView] = useState(false);

    const ALLOWED_TRANSITIONS = {
        [INITIAL_STATE]: [BRIDGE_CONNECTING_STATE],
        [BRIDGE_CONNECTING_STATE]: [ROBO_LIST_FETCHING_STATE, BRIDGE_ERROR_STATE],
        [ROBO_LIST_FETCHING_STATE]: [ROBO_LIST_FETCHED_STATE],
        [ROBO_LIST_FETCHED_STATE]: [ROBO_CONNECTED_STATE],
        [ROBO_CONNECTED_STATE]: [ROBO_DISCONNECTED_STATE],
        [ROBO_DISCONNECTED_STATE]: [ROBO_CONNECTED_STATE],
    };

    const GLOBAL_ALLOWED_STATES = [
        INITIAL_STATE,
        BRIDGE_CONNECTION_LOST_STATE,
        BRIDGE_MANUALLY_DISCONNECTED_STATE,
        ROBO_DISCONNECTED_STATE
    ];

    const transitStateTo = (neededState) => {
        const validNextStates = [
            ...(ALLOWED_TRANSITIONS[currentConnectionState] || []),
            ...GLOBAL_ALLOWED_STATES
        ];

        console.log('[TRYING] to set state:', currentConnectionState, '-->', neededState);
        if (validNextStates.includes(neededState)) {
            console.log('[PERFORMING] set state', currentConnectionState, '-->', neededState);
            setCurrentConnectionState(neededState);
        } else {
            console.warn(`Transition from ${currentConnectionState} to ${neededState} is not allowed.`);
        }
    };

    useEffect(() => {
        if (isBridgeConnecting) {
            transitStateTo(BRIDGE_CONNECTING_STATE);
        }
    }, [isBridgeConnecting]);

    useEffect(() => {
        if (isBridgeConnected) {
            transitStateTo(ROBO_LIST_FETCHING_STATE);
        } else if (isBridgeConnectionLost) {
            transitStateTo(BRIDGE_CONNECTION_LOST_STATE);
        } else if (isBridgeManuallyDisconnected) {
            transitStateTo(INITIAL_STATE)
        }
    }, [isBridgeConnected, isBridgeConnectionLost, isBridgeManuallyDisconnected]);

    useEffect(() => {
        if (isBridgeError) {
            transitStateTo(BRIDGE_ERROR_STATE);
        }
    }, [isBridgeError]);

    useEffect(() => {
        if (isRoboListFetched) {
            transitStateTo(ROBO_LIST_FETCHED_STATE);
        }
    }, [isRoboListFetched]);

    useEffect(() => {
        if (isRoboConnected) {
            transitStateTo(ROBO_CONNECTED_STATE);
        } else {
            console.log('Robo disconnected');
        }
    }, [isRoboConnected]);


    switch (currentConnectionState) {
        case INITIAL_STATE:
            return <></>

        case BRIDGE_CONNECTING_STATE:
            return <ConnectingView />;

        case BRIDGE_CONNECTION_LOST_STATE:
        case BRIDGE_ERROR_STATE:
            return <BridgeErrorView />;

        case ROBO_LIST_FETCHING_STATE:
            return <></>;

        case ROBO_LIST_FETCHED_STATE:
            return <RoboListView robos={roboList} handleRoboConnect={handleRoboConnect} open={true} setOpen={setShowRoboListView} />;

        case ROBO_CONNECTED_STATE:
            return <ConnectedView />;

        case ROBO_DISCONNECTED_STATE:
            return <></>;

        default:
            throw new Error(`Invalid state: ${currentConnectionState}`);
    }
};

export default RoboConnectionManager;
