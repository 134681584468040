export const GRID_WIDTH = 12
const MOBILE_BREAKPOINT = 752
export const GRID_BREAKPOINTS = {
  desktop: MOBILE_BREAKPOINT + 1,
  mobile: MOBILE_BREAKPOINT,
}

export const GRID_COLUMNS = {
  desktop: GRID_WIDTH,
  mobile: 7,
}

export const GRID_ASPECT_RATIO = 10 / 9
export const MIN_ROW_HEIGHT = 40
