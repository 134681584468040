import React, { useContext } from 'react'
import { Typography } from '@mui/material'

import { colors as themeColors } from '@themes/config/theme-colors'
import StyledIconButton from './styled-icon-button'
import { EditorContext } from '@webapp/components/hoc/WithEditor'
import { RoboContext } from '@webapp/providers/robo-context'

const SoundIconButton = ({ soundType, width, height, children, onClick, type }) => {
  const showplayButton = useContext(EditorContext)

  const { robo } = useContext(RoboContext)

  const SOUND = robo?.systems['SYSTEM_1']

  const handleClick = soundType => {
    return SOUND?.playSound(soundType)
  }

  return (
    <StyledIconButton
      color="#FA612D"
      width={width}
      height={height}
      onClick={showplayButton ? null : onClick || (() => handleClick(type))}
      size="large"
    >
      {children}
      <Typography variant="x-tiny-bold" color={themeColors.white['500']}>
        {soundType}
      </Typography>
    </StyledIconButton>
  )
}

export default React.memo(SoundIconButton)
