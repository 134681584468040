import React, { useEffect } from 'react'

import { useNav } from '@lib/hooks/index'
import { Box, Grid, Icon, IconButton, Typography, Button } from '@mui/material'
import { Paper } from '@components/Paper'

import Avatar from '@components/Avatar'

import { Edit as EditIcon } from '@mui/icons-material'

const Profile = ({ ...props }) => {
    const { setPage } = useNav()

    useEffect(() => {
        setPage('webapp')
    })

    return (
        <>
            <Typography variant='x-headline1-black'>Profile</Typography>

            <Paper sx={{ mt: 2, minHeight: '470px', background: `#fff url(./assets/profile-background.png)` }}>
                <Grid container spacing={2} justifyContent={'stretch'}>
                    <Grid item xs={11}>
                        <Avatar />
                    </Grid>
                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button sx={{
                            width: '64px',
                            height: '64px',
                            border: '1px solid #5A418B',
                            borderRadius: '50%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <EditIcon />

                            <Typography variant='x-body-bold' sx={{}}>Edit</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </Paper >
        </>
    )
}

export default Profile
