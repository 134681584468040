import React from 'react'

import { Box, Modal, Typography, Button } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const NoEmptySpaceModal = ({ open, closeModal }) => {
  const handleClose = () => closeModal()

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="x-small-semibold">
            No empty space!
          </Typography>

          <Button
            variant="contained"
            size={'small'}
            onClick={handleClose}
            sx={{ marginTop: '15px', borderRadius: '10px' }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default NoEmptySpaceModal
