import React, { useContext } from 'react'
import StyledBox from '@webapp/components/blocks/component/styled-box'
import { Joystick } from 'react-joystick-component'
import { EditorContext } from '@webapp/components/hoc/WithEditor'

const JoystickSvgBackground = `
  <svg xmlns="http://www.w3.org/2000/svg" width="155" height="155" fill="none">
  <circle cx="77.5" cy="77.5" r="77.5" fill="#fff" fill-opacity=".125" />
  <path opacity=".299" fill-rule="evenodd" clip-rule="evenodd"
      d="M75.336 12.496a2 2 0 013.328 0l4.263 6.395C83.813 20.22 82.86 22 81.263 22h-8.526c-1.597 0-2.55-1.78-1.664-3.11l4.263-6.394zm0 129.008a2 2 0 003.328 0l4.263-6.395c.886-1.329-.067-3.109-1.664-3.109h-8.526c-1.597 0-2.55 1.78-1.664 3.109l4.263 6.395zm66.168-66.168a2 2 0 010 3.328l-6.395 4.263c-1.329.886-3.109-.067-3.109-1.664v-8.526c0-1.597 1.78-2.55 3.109-1.664l6.395 4.263zm-129.008 0a2 2 0 000 3.328l6.395 4.263C20.22 83.813 22 82.86 22 81.263v-8.526c0-1.597-1.78-2.55-3.11-1.664l-6.394 4.263z"
      fill="#fff" />
</svg>
    `

const JoystickControl = () => {
  const showplayButton = useContext(EditorContext)

  return (
    <StyledBox color="#156BFB">
      <Joystick
        size={155}
        sticky={false}
        baseImage={`data:image/svg+xml;base64,${btoa(JoystickSvgBackground)}`}
        stickColor="white"
        stickSize={80}
        move={value => console.log(value)}
        disabled={showplayButton}
      ></Joystick>
    </StyledBox>
  )
}

export default JoystickControl
