import React, { useState, useContext, useEffect } from 'react';

import { IconButton, Popper, Box, ClickAwayListener, Button, TextField, Grid, Typography, Slider } from '@mui/material';

import { BridgeContext } from '@webapp/providers/bridge-context';
import { RoboContext } from '@webapp/providers/robo-context';

import { MainBlockIcon, VolumeUpIcon, VolumeDownIcon, ProgressiveBatteryIcon } from '@components/icons';

// todo Separate into multiple components

const ConnectButton = () => {
    const {
        handleBridgeConnect,
        handleBridgeDisconnect,
        isConnected: isBridgeConnected,
        isConnecting: isBridgeConnecting,
        isError: isBridgeError,

        bridgeName,
        setBridgeName,
    } = useContext(BridgeContext);

    const {
        isConnected: isRoboConnected,
        robo,
        roboName
    } = useContext(RoboContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowRef, setArrowRef] = useState(null);
    const [open, setOpen] = useState(false);

    const roboSystem = robo?.systems['SYSTEM_1'];
    const [volume, setVolume] = useState(roboSystem?.loudness || 50);

    // todo Take from design system
    const colors = {
        connected: '#1CA543',
        connecting: '#CBDBFD',
        error: '#DD383F',
        default: '#F26730',
    };

    // Calculate the ring color
    let color = colors.default;

    if (isBridgeConnected) {
        if (isRoboConnected) {
            color = colors.connected;
        } else {
            color = colors.connecting;
        }
    } else if (isBridgeConnecting) {
        color = colors.connecting;
    } else if (isBridgeError) {
        color = colors.error;
    }

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickAway = (event) => {
        if (anchorEl && anchorEl.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleStartConnecting = () => {
        setAnchorEl(null);
        setOpen(false);
        handleBridgeConnect();
    };

    const handleDisconnect = () => {
        setAnchorEl(null);
        setOpen(false);
        handleBridgeDisconnect();
    }

    const handleIncreaseVolumeClick = () => {
        const newVolume = Math.min(volume + 10, 100);
        setVolume(newVolume);
        roboSystem.setLoudness(newVolume);
    };

    const handleDecreaseVolumeClick = () => {
        const newVolume = Math.max(volume - 10, 0);
        setVolume(newVolume);
        roboSystem.setLoudness(newVolume);
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
    };

    useEffect(() => {
        if (roboSystem) {
            setVolume(roboSystem.loudness);
        }
    }, [roboSystem])

    return (
        <>
            <IconButton
                onClick={handleButtonClick}
                size='large'
                sx={{
                    borderColor: color,
                    borderWidth: '4px',
                    borderStyle: 'solid',
                    width: '48px',
                    height: '48px',
                    boxSizing: 'border-box',
                    ml: 3,
                }}>
                <MainBlockIcon />
            </IconButton>

            <ClickAwayListener onClickAway={handleClickAway}>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom"
                    onClose={handleClose}
                    sx={{ zIndex: 9999 }}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                            options: {
                                altBoundary: true,
                                rootBoundary: 'document',
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: true,
                                altBoundary: true,
                                tether: true,
                                rootBoundary: 'viewport',
                                padding: 8,
                            },
                        },
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                        {
                            name: "offset",
                            options: {
                                offset: [0, 10],
                            },
                        }

                    ]}
                >
                    <Box
                        sx={{
                            position: "relative",
                            mt: "10px",
                            "&::before": {
                                backgroundColor: "white",
                                content: '""',
                                display: "block",
                                position: "absolute",
                                width: 12,
                                height: 12,
                                top: -6,
                                transform: "rotate(45deg)",
                                left: "calc(50% - 6px)",
                                borderLeft: "1px solid rgba(0,0,0,.1)",
                                borderTop: "1px solid rgba(0,0,0,.1)",
                                borderRadius: "4px 0 0 0",
                            },
                        }}
                    />

                    <Box
                        component="span"
                        className="arrow"
                        ref={setArrowRef}
                        sx={{ position: "absolute", fontSize: 7 }}
                    />

                    <Box
                        sx={{
                            p: 2,
                            backgroundColor: "white",
                            border: "1px solid rgba(0,0,0,.1)",
                            borderRadius: "20px",
                        }}
                    >
                        {isBridgeConnected ?
                            <>
                                <Grid container spacing={0}
                                    sx={{
                                        width: '270px',
                                        color: '#5A418B',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            title={`${roboSystem?.batteryCapacity}% ${roboSystem?.batteryStatus}`}
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                minWidth: '40px',
                                                minHeight: '40px',
                                                padding: '10px',
                                                background: '#F4F4F4',
                                                borderRadius: '50%',
                                                position: 'absolute',
                                                right: '5px',
                                                top: '15px',
                                            }}>
                                            <ProgressiveBatteryIcon progress={roboSystem?.batteryCapacity} />
                                        </Button>
                                    </Grid>

                                    {robo && <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                                        <Typography variant='x-xsmall-black'>You're connected to:</Typography>
                                    </Grid>}

                                    <Grid item xs={12}>
                                        <Typography variant='x-small-bold'>{roboName}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            variant="text"
                                            color='primary'
                                            size='medium'
                                            sx={{
                                                background: '#F9F7FB',
                                                mt: 2, mb: 2
                                            }}
                                            onClick={handleDisconnect}
                                        >
                                            Disconnect
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant='x-xsmall-regular'>version:  {roboSystem?.firmware}</Typography>
                                    </Grid>
                                </Grid>

                                {roboSystem && (<Box sx={{
                                    background: '#F9F7FB',
                                    mt: 2,
                                    mb: -2,
                                    ml: -2,
                                    mr: -2,
                                    pt: 2,
                                    borderRadius: '0 0 20px 20px',
                                    minHeight: '77px',
                                    color: '#5A418B',
                                    textAlign: 'center',
                                }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='x-xsmall-black'>Adjust the volume</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 1 }}>
                                            <Grid container sx={{ width: '80%' }} >
                                                <Grid item sx={{ paddingTop: '3px' }}>
                                                    <VolumeDownIcon fill={'#5A418B'} onClick={handleDecreaseVolumeClick} />
                                                </Grid>

                                                <Grid item xs sx={{ pl: 2, pr: 2 }}>
                                                    <Slider
                                                        value={volume}
                                                        onChange={handleVolumeChange}
                                                        onChangeCommitted={(e) => {
                                                            roboSystem.setLoudness(volume)
                                                        }}

                                                        aria-labelledby="continuous-slider"
                                                        sx={{
                                                            '& .MuiSlider-rail': {
                                                                background: '#5A418B',
                                                                opacity: 1,
                                                                height: '2px'
                                                            },
                                                            '& .MuiSlider-track': {
                                                                display: 'none',
                                                            },
                                                        }}

                                                    />
                                                </Grid>

                                                <Grid item sx={{ paddingTop: '3px' }}>
                                                    <VolumeUpIcon fill={'#5A418B'} onClick={handleIncreaseVolumeClick} />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Box>
                                )}
                            </>
                            :
                            <Grid container spacing={0} sx={{ width: '170px' }}>
                                <Grid item xs={12}>
                                    <Button variant="contained" sx={{ height: "76px" }} disabled={!bridgeName} onClick={handleStartConnecting}>
                                        Connect your <br /> Main Block
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <TextField
                                        placeholder={'Bridge name'}
                                        value={bridgeName}
                                        variant='outlined'
                                        sx={{ m: 0, pt: 0 }}
                                        size='small'
                                        onChange={(e) => setBridgeName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Popper>
            </ClickAwayListener >
        </>
    );
};

export default ConnectButton;
