import React from 'react'

import { styled } from '@mui/material/styles'

import {
  Paper,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material'

import { useLocation } from '@reach/router'

import { colors as themeColors } from '@themes/config/theme-colors'
import { navigate } from '@lib/utils/navigate'
import { WebAppIcon, TutorialsIcon, LibraryIcon } from '@components/icons'
import { Logo } from '@components/Logo/Logo'

import ConnectButton from './top-bar/connect-button'

const HorizontalMenu = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '0px',
  paddingBottom: '0px',
}))

const MenuItem = styled(ListItem)(({ theme }) => ({}))

const MenuItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '8px',
  paddingLeft: '4px',
  '&.Mui-selected': {
    background: themeColors.violet.main,

    '&:hover': {
      background: themeColors.violet['900'],
    }
  },
}))

const MenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: '38px',
  height: '38px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: themeColors.violet['900'],
  fill: themeColors.violet['900'],

  '.Mui-selected &': {
    color: themeColors.white.main,
    fill: theme.palette.common.white,
  },
}))

const MenuItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '2px',
  marginTop: '4px',
  '.Mui-selected &': {
    color: theme.palette.common.white,
  },
}))

const EditorButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '6px',
  padding: '7px',

  '&.Mui-selected': {
    background: '#fff',
    boxShadow: '0px 0px 19px rgba(0, 0, 0, 0.16)',
  },
}))

const EditorItem = styled(ListItem)(({ theme }) => ({
  padding: '0px',
  margin: '3px',
}))

const EditorItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '2px',
  marginTop: '4px',
}))

const EditorItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
}))

export const TopBar = () => {
  const location = useLocation()

  const handleNavigation = path => {
    navigate(path)
  }

  const normalizedLocation = location.pathname.replace(/\/$/, '')

  return (
    <Paper sx={{ m: 6, mt: 4 }}>
      <Grid container justifyContent={'stretch'} sx={{ height: '80px', mt: -1 }}>
        <Grid item>
          <Box
            sx={{
              width: '92px',
              height: '100%',
              background: themeColors.secondary.liveApp,
              display: 'flex',
              alignItems: 'center',
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              justifyContent: 'center',
              color: themeColors.violet.main,
              fill: themeColors.violet.main,
            }}
          >
            <WebAppIcon sx={{ width: '36px', height: '36px' }} />
          </Box>
        </Grid>

        <Grid item xs={6} lg={7} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
          <HorizontalMenu>
            <MenuItem>
              <MenuItemButton
                selected={normalizedLocation === '/webapp/projects'}
                onClick={() => handleNavigation('/webapp/projects')}
              >
                <MenuItemIcon>
                  <LibraryIcon />
                </MenuItemIcon>

                <MenuItemText>
                  <Typography variant="x-body-bold">Projects</Typography>
                </MenuItemText>
              </MenuItemButton>
            </MenuItem>

            <MenuItem>
              <MenuItemButton
                selected={normalizedLocation === '/webapp/tutorials'}
                onClick={() => handleNavigation('/webapp/tutorials')}
              >
                <MenuItemIcon>
                  <TutorialsIcon />
                </MenuItemIcon>

                <MenuItemText>
                  <Typography variant="x-body-bold">Tutorials</Typography>
                </MenuItemText>
              </MenuItemButton>
            </MenuItem>
          </HorizontalMenu>
        </Grid>

        <Grid item xs={4} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
          <HorizontalMenu
            sx={{
              backgroundColor: '#F4F4F4',
              borderRadius: '8px',
            }}
          >
            <EditorItem>
              <EditorButton
                selected={normalizedLocation === '/webapp/live'}
                onClick={() => handleNavigation('/webapp/live')}
              >
                <EditorItemIcon>
                  <Logo variant="live" width="36px" height="36px" />
                </EditorItemIcon>

                <EditorItemText>
                  <Typography variant="x-xsmall-bold">Live</Typography>
                </EditorItemText>
              </EditorButton>
            </EditorItem>

            <EditorItem>
              <EditorButton
                selected={normalizedLocation === '/webapp/code'}
                onClick={() => handleNavigation('/webapp/code')}
              >
                <EditorItemIcon>
                  <Logo variant="code" width="36px" height="36px" />
                </EditorItemIcon>

                <EditorItemText>
                  <Typography variant="x-xsmall-bold">Code</Typography>
                </EditorItemText>
              </EditorButton>
            </EditorItem>
          </HorizontalMenu>

          <ConnectButton />

          <Logo variant="profile" width="48px" height="48px" ml="30px" onClick={() => handleNavigation('/webapp/profile')} />
        </Grid>
      </Grid>
    </Paper>
  )
}
