import React, { useEffect } from 'react'

import RoboCodeEditor from '@webapp/components/editors/robo-code';
import withEditor from '@webapp/components/hoc/WithEditor'

const initialNodes = [
  {
    id: 'O',
    data: { label: 'Start - O', isStartNode: true, components: {} },
    position: { x: 50, y: 50 },
    type: 'custom',
  },
  {
    id: 'A',
    data: { label: 'A' },
    position: { x: 300, y: 50 },
    type: 'custom',
  },
  {
    id: 'B',
    data: { label: 'B' },
    position: { x: 200, y: 300 },
    type: 'custom',
  },
  {
    id: 'C',
    data: { label: 'C' },
    position: { x: 50, y: 300 },
    type: 'custom',
  },
];

const initialEdges = [
  { id: 'O-A', source: 'O', target: 'A', label: 'O-A', type: 'custom', data: { components: {} } },
  { id: 'A-B', source: 'A', target: 'B', label: 'A-B', type: 'custom' },
  { id: 'B-A', source: 'B', target: 'A', label: 'B-A', type: 'custom' }
];

const RoboCode = ({ sendDataToHOC }) => {
  useEffect(() => {
    sendDataToHOC('code')
  }, [])

  return <RoboCodeEditor initialNodes={initialNodes} initialEdges={initialEdges} />
}

export default withEditor(RoboCode)
