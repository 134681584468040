import React, { useState } from 'react'

import { Box } from '@mui/material'

import ScrollToTop from 'react-scroll-to-top'

import { colors as themeColors } from '@themes/config/theme-colors'

import { Logo } from '@components/Logo/Logo'
import { MainMenu } from '@components/Menu/MainMenu'

import { BridgeProvider } from '@webapp/providers/bridge-context'
import { RoboProvider } from '@webapp/providers/robo-context'
import RoboConnectionManager from '@webapp/components/connection-client/robo-connection-manager'

import { TopBar } from '@webapp/layouts/partials/top-bar'

import BackgroundSvgBL from '@webapp/assets/background/bottom-left.svg'
import BackgroundSvgBR from '@webapp/assets/background/bottom-right.svg'

const WebappLayout = ({ children }) => {
  const [isMainMenuOpened, setIsMainMenuOpened] = useState(false)

  let sidebarWidth = isMainMenuOpened ? 394 : 110
  let headerHeight = 110
  let footerHeight = 50

  return (
    <BridgeProvider>
      <RoboProvider>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `0px 1fr`,
            gridTemplateRows: `${headerHeight}px 1fr ${footerHeight}px`,
            gap: 0,
            gridTemplateAreas: `
                    "sidebar header"
                    "sidebar content"
                    "sidebar content"
                    `,
            height: '100vh',
            transition: '0.2s',
            zIndex: 10,
            overflow: 'hidden',
          }}
        >
          {/* <Box
          sx={{
            gridArea: 'sidebar',
            position: 'fixed',
            height: '100vh',
            width: `${sidebarWidth}px`,
            transition: 'width 0.2s',
            backgroundColor: themeColors.white.main,
            zIndex: 20,
          }}

          onMouseEnter={() => setIsMainMenuOpened(true)}
          onMouseLeave={() => setIsMainMenuOpened(false)}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'left',
              height: headerHeight,
              borderBottom: `1px solid ${themeColors.black['100']}`,
              paddingLeft: '40px',
            }}
          >
            <Logo variant="main" width="30px" height="30px" />
          </Box>

          <MainMenu
            open={isMainMenuOpened}
            closeHandler={() => setIsMainMenuOpened(false)}
            sx={{
              borderRight: `1px solid ${themeColors.black['100']}`,
            }}
          />
        </Box> */}

          <Box
            sx={{
              gridArea: 'header', // todo Uncomment position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#F5F5F5',
            }}
          >
            <TopBar />
          </Box>

          <Box
            sx={{
              gridArea: 'content',
              zIndex: 4,
              padding: '32px 64px 0 64px',
              background: `url(${BackgroundSvgBL}) bottom left / 560px 560px no-repeat, url(${BackgroundSvgBR}) bottom right / 900px 900px no-repeat, #F5F5F5`,
            }}
          >
            {children}
          </Box>

        </Box>

        <ScrollToTop smooth style={{ zIndex: 9999, right: '8px', bottom: '16px' }} />


        <RoboConnectionManager />
      </RoboProvider>
    </BridgeProvider>
  )
}

export default WebappLayout
