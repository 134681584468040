import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material';

const RoboListView = ({ robos, handleRoboConnect, open, setOpen }) => {
    return (
        <Dialog open={open} onClose={() => { }} slotProps={{
            backdrop: {
                sx: {
                    background: 'rgba(126, 99, 182, 0.6)'
                }
            }
        }}>
            <DialogTitle sx={{ textAlign: 'center' }}>
                <Typography variant="x-headline3-black"> Choose your Robot </Typography>
            </DialogTitle>

            <DialogContent sx={{ background: '#F5F5F5', minWidth: '500px' }}>
                {!robos.length && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                            <Typography variant="x-body-1">No Robots Found</Typography>
                        </Grid>
                    </Grid>
                )}

                {robos.map((robo) => (
                    <Button
                        key={robo.name}
                        onClick={() => handleRoboConnect(robo.name)}
                        fullWidth
                        variant='contained'
                        size='large'
                        disabled={robo.connected}
                        sx={{ mt: 3 }}
                    >
                        <Grid container>
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <Typography variant="x-body-1">{robo.name}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                {!robo.connected && <Typography variant="x-body-1">Connect</Typography>}
                            </Grid>
                        </Grid>
                    </Button>
                ))}
            </DialogContent>

            {/* <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }}>Close</Button>
            </DialogActions> */}
        </Dialog>
    );
};




export default RoboListView;