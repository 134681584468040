import React, { useEffect } from 'react'

import withEditor from '@webapp/components/hoc/WithEditor'
import RoboLiveEditor from '@webapp/components/editors/robo-live';

const RoboLive = ({ sendDataToHOC, ...rest }) => {
  useEffect(() => {
    sendDataToHOC('live')
  }, [])

  return <RoboLiveEditor {...rest} />
}

export default withEditor(RoboLive)
