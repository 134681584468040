import React from 'react';

const Avatar = () => {
    return (
        <div>
            Avatar component. TBD
        </div>
    );
};

export default Avatar;
