import React, { useState, useContext } from 'react'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors as themeColors } from '@themes/config/theme-colors'
import { LightSensorIcon } from '@components/icons'
import StyledIconButton from '../../component/styled-icon-button'

import { EditorContext } from '@webapp/components/hoc/WithEditor'

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-xsmall-semibold',
  color: themeColors.white['500'],
}))

const LightSensorControl = ({ widgetNumber }) => {
  const [motionSensorOn, setMotionSensorOn] = useState(false)
  const showplayButton = useContext(EditorContext)

  const handleMotionSensorChange = () => {
    setMotionSensorOn(!motionSensorOn)
  }

  return (
    <StyledIconButton
      color="#E31871"
      width="100%"
      height="100%"
      onClick={showplayButton ? null : handleMotionSensorChange}
      size="large"
    >
      {!!widgetNumber && <StyledTypography variant="x-lead-semibold">{widgetNumber}</StyledTypography>}

      <LightSensorIcon
        primaryColor={motionSensorOn ? '#fff' : 'rgba(255, 255, 255, 0.3)'}
        sx={{ width: '48px', height: '48px' }}
      />
    </StyledIconButton>
  )
}

export default React.memo(LightSensorControl)
