export const generateGridBackground = ({ cellSize, margin, cols, gridWidth }) => {
  const XMLNS = 'http://www.w3.org/2000/svg'
  const [horizontalMargin, verticalMargin] = margin
  const rowHeight = cellSize.height + verticalMargin
  const cellStrokeColor = '#156BFB'

  const dotGrid = (cellWidth, cellHeight) => {
    const dotRadius = Math.min(cellWidth, cellHeight) * 0.1
    const dotSpacing = Math.min(cellWidth, cellHeight) * 0.15

    let dots = ''
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 3; col++) {
        const cx = cellWidth * 0.5 + (col - 1) * (dotRadius * 2 + dotSpacing)
        const cy = cellHeight * 0.5 + (row - 1) * (dotRadius * 2 + dotSpacing)
        dots += `<circle cx="${cx}" cy="${cy}" r="${dotRadius}" fill="${dotStyle.backgroundColor}" />`
      }
    }

    return dots
  }

  const dotStyle = {
    backgroundColor: 'rgba(39, 39, 39, 0.05)',
  }

  const y = 0
  const w = cellSize.width
  const h = cellSize.height
  const rectangles = Array.from({ length: cols }, (_, i) => {
    const x = i * (cellSize.width + horizontalMargin)
    return `
        <rect  fill="none" x="${x}" y="${y}" width="${w}" height="${h}"/>
        <g transform="translate(${x}, ${y})">
          ${dotGrid(cellSize.width, cellSize.height)}
        </g>
      `
  })

  const svg = [`<svg xmlns="${XMLNS}" width="${gridWidth}" height="${rowHeight}">`, ...rectangles, `</svg>`].join('')

  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`
}

export const getPositionForNewElement = (layout, newCardSize, cols) => {
  let row = 0
  let col = 0

  const cards = layout.map(item => {
    // Convert react-grid-layout item to our custom format
    return {
      col: item.x,
      row: item.y,
      size_x: item.w,
      size_y: item.h,
    }
  })

  while (row < 5) {
    while (col <= cols - newCardSize.w) {
      let good = true
      const position = { col, row, size_x: newCardSize.w, size_y: newCardSize.h }

      // Check if the item height from the new position exceeds the available rows
      if (position.row + position.size_y > 5) {
        good = false
        break
      }

      for (const card of cards) {
        if (intersects(card, position)) {
          good = false
          break
        }
      }
      if (good) {
        // Convert the position back to react-grid-layout format
        const newPosition = {
          x: position.col,
          y: position.row,
          w: position.size_x,
          h: position.size_y,
          i: `new_card_${layout.length}`,
          isSpaceAvailable: true,
        }
        return newPosition
      }
      col++
    }
    col = 0
    row++
  }

  const newPosition = {
    isSpaceAvailable: false,
  }

  return newPosition
}

function intersects(a, b) {
  return !(
    b.col >= a.col + a.size_x ||
    b.col + b.size_x <= a.col ||
    b.row >= a.row + a.size_y ||
    b.row + b.size_y <= a.row
  )
}
