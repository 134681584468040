import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const RoboCodeContext = createContext();

export const CODE_EDITOR_MODES = {
  BUILD: 'build',
  CONNECT: 'connect',
  RUN: 'run',
  LOCK: 'lock'
}

export function RoboCodeProvider({ children }) {
  const [mode, setMode] = useState(CODE_EDITOR_MODES.BUILD);

  return (
    <RoboCodeContext.Provider value={{
      mode, setMode
    }}>
      {children}
    </RoboCodeContext.Provider>
  );
}

RoboCodeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};