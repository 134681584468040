import React from 'react';

import { getStraightPath } from 'reactflow';

function CustomConnectionLine({ fromNode, fromX, fromY, toX, toY, connectionLineStyle }) {
    const defaultConnectionLineStyle = {
        stroke: 'white',
    };

    const lineStyle = { ...defaultConnectionLineStyle, ...connectionLineStyle };

    // Offset the source coordinates by 50px in the direction of the line
    const offset = fromNode.width / 2;
    // const offset = connectionLineStyle.dataOffset || 50;

    // Calculate the direction of the line
    const dx = toX - fromX;
    const dy = toY - fromY;
    const length = Math.sqrt(dx * dx + dy * dy);

    if (length < offset) {
        return null
    }

    // Normalize the direction to get a unit vector
    const ux = dx / length;
    const uy = dy / length;

    const newFromX = fromX + ux * offset;
    const newFromY = fromY + uy * offset;

    const [edgePath] = getStraightPath({
        sourceX: newFromX,
        sourceY: newFromY,
        targetX: toX,
        targetY: toY,
    });

    return (
        <g>
            <path style={lineStyle} fill="#5A418B" d={edgePath} />
            <circle cx={toX} cy={toY} fill="5A418B" r={3} stroke="white" strokeWidth={1.5} />
        </g>
    );
}

export default CustomConnectionLine;
