import React, { useContext } from 'react';

import { Controls, useReactFlow } from 'reactflow';
import { Grid, Typography, Button, ToggleButton, Stack } from '@mui/material';

import { ConnectionModeIcon, ZoomInIcon, ZoomOutIcon } from '@components/icons';

import { RoboCodeContext, CODE_EDITOR_MODES } from '../providers/robo-code-context'

const CustomControls = () => {
    const { zoomIn, zoomOut } = useReactFlow();
    const { mode, setMode } = useContext(RoboCodeContext);

    const handleToggleConnectMode = () => {
        if (mode === CODE_EDITOR_MODES.CONNECT) {
            setMode(CODE_EDITOR_MODES.BUILD);
        } else {
            setMode(CODE_EDITOR_MODES.CONNECT);
        }
    }

    return (
        <Controls
            position='bottom-right'
            showInteractive={false}
            showFitView={false}
            showZoom={false}
            style={{
                width: '96px',
                zIndex: 9999,
                border: 'none',
                boxShadow: 'none',
                margin: '0 0 40px 0',
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button onClick={zoomIn} fullWidth sx={{ height: '96px' }}>
                        <Stack direction='column' alignItems={'center'} spacing={0}>
                            <ZoomInIcon sx={{
                                width: '48px',
                                height: '48px',
                            }} />

                            <Typography variant='x-xsmall-black'>Zoom in</Typography>
                        </Stack>
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={zoomOut} fullWidth sx={{ height: '96px' }}>
                        <Stack direction='column' alignItems={'center'} spacing={0}>
                            <ZoomOutIcon sx={{
                                width: '48px',
                                height: '48px',
                            }} />

                            <Typography variant='x-xsmall-black'>Zoom out</Typography>
                        </Stack>
                    </Button>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <ToggleButton
                        variant='contained'
                        selected={mode === CODE_EDITOR_MODES.CONNECT}
                        onChange={handleToggleConnectMode}
                        value="connect-toggle"

                        sx={{
                            width: '80px',
                            height: '80px',
                            backgroundColor: '#5A418B',
                            borderRadius: '50%',
                            color: 'white',
                        }}
                    >
                        <Stack direction='column' alignItems={'center'} spacing={0}>
                            <ConnectionModeIcon sx={{
                                width: '36px',
                                height: '36px',
                            }} />

                            <Typography variant='x-xsmall-black'>Connect</Typography>
                        </Stack>
                    </ToggleButton>
                </Grid>
            </Grid>
        </Controls>
    );
};

export default CustomControls;
