import React, { useState, useContext } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors as themeColors } from '@themes/config/theme-colors'
import { RgbLightIcon } from '@components/icons'
import { SensorTestIcon } from '@components/icons'
import StyledBox from '@webapp/components/blocks/component/styled-box'
import StyledSlider from '@webapp/components/blocks/component/styled-slider'
import { EditorContext } from '@webapp/components/hoc/WithEditor'

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '8px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}))

const MotionSensorControl = ({ widgetNumber, width, height }) => {
  const [value, setValue] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const showplayButton = useContext(EditorContext)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  return (
    <StyledBox
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      width={width}
      height={height}
      color="#E31871"
    >
      {console.log(value)}
      {widgetNumber ? <StyledTypography variant="x-lead-semibold">{widgetNumber}</StyledTypography> : null}
      <RgbLightIcon sx={{ width: '25px', height: '25px', marginBottom: '15px' }} />
      <StyledSlider
        orientation="vertical"
        min={0}
        max={15}
        value={value}
        onChange={handleSliderChange}
        disabled={showplayButton}
      />
      <SensorTestIcon sx={{ width: '15px', height: '15px' }} />
    </StyledBox>
  )
}

export default MotionSensorControl
