import React, { useState, useContext } from 'react'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors as themeColors } from '@themes/config/theme-colors'
import { LookingFaceIcon } from '@components/icons'
import StyledIconButton from '../../component/styled-icon-button'

import { EditorContext } from '@webapp/components/hoc/WithEditor'

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-xsmall-semibold',
  color: themeColors.white['500'],
}))

const LookingFaceControl = ({ widgetNumber }) => {
  const [lookingFaceOn, setLookingFaceOn] = useState(false)
  const showplayButton = useContext(EditorContext)

  const handleLookingFaceChange = () => {
    setLookingFaceOn(!lookingFaceOn)
  }

  return (
    <StyledIconButton
      color="#FFB71C"
      width="100%"
      height="100%"
      onClick={showplayButton ? null : handleLookingFaceChange}
      size="large"
    >
      {!!widgetNumber && <StyledTypography variant="x-lead-semibold">{widgetNumber}</StyledTypography>}
      <LookingFaceIcon sx={{ width: '79px', height: '58px', paddingTop: '15px' }} />
    </StyledIconButton>
  )
}

export default LookingFaceControl
