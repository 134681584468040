import React, { useState, useEffect, useCallback, useMemo, Component } from 'react'
import { Box } from '@mui/material'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './styles.css'
import _ from 'lodash'

import { Responsive as ReactGridLayout } from 'react-grid-layout'
import { generateGridBackground } from './grid-utils'

const GridLayout = ({
  className = 'layout',
  layouts,
  onLayoutChange = () => {},
  columnsMap,
  breakpoints,
  onBreakpointChange,
  margin,
  gridWidth,
  cols,
  currentBreakpoint,
  isDraggable,
}) => {
  const [mounted, setMounted] = useState(false)
  const [rowHeight, setRowHeight] = useState(0)

  useEffect(() => {
    setMounted(true)
  }, [])

  const cellSize = useMemo(() => {
    const marginSlotsCount = cols - 1
    const [horizontalMargin] = margin
    const totalHorizontalMargin = marginSlotsCount * horizontalMargin
    const freeSpace = gridWidth - totalHorizontalMargin

    const widthandrowHeight = freeSpace / cols
    return {
      width: widthandrowHeight,
      height: widthandrowHeight,
    }
  }, [cols, gridWidth, rowHeight, margin])

  useEffect(() => {
    setRowHeight(cellSize.height)
  }, [cellSize])

  const background = useMemo(
    () => generateGridBackground({ cellSize, margin, cols, gridWidth }),
    [cellSize, gridWidth, margin, cols]
  )

  const style = useMemo(
    () => ({
      width: gridWidth,
      height: '540px',
      background: background,
    }),
    [gridWidth, background]
  )

  const generateDOM = useCallback(() => {
    return _.map(layouts[currentBreakpoint], l => <Box key={l.i}>{l.component}</Box>)
  }, [currentBreakpoint, layouts])

  const onLayoutChangeWrapped = useCallback(
    nextLayout => {
      console.log('nextLayout', nextLayout)
      onLayoutChange({
        layouts: nextLayout,
        // Calculating the breakpoint right here,
        // so we're definitely passing the most recent one
        // Workaround for https://github.com/react-grid-layout/react-grid-layout/issues/889
        breakpoint: ReactGridLayout.utils.getBreakpointFromWidth(breakpoints, gridWidth),
      })
    },
    [onLayoutChange, breakpoints, gridWidth, layouts]
  )

  return (
    <>
      <ReactGridLayout
        breakpoints={breakpoints}
        className={className}
        layouts={layouts}
        width={gridWidth}
        cols={columnsMap}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChangeWrapped}
        rowHeight={rowHeight}
        measureBeforeMount={false}
        isResizable={false}
        isDraggable={isDraggable}
        useCSSTransforms={mounted}
        compactType="horizontal"
        preventCollision={true}
        style={style}
        autoSize={false}
        containerPadding={[0, 0]}
        margin={margin}
        allowOverlap={false}
        maxRows={5}
      >
        {generateDOM()}
      </ReactGridLayout>
    </>
  )
}

export default GridLayout
