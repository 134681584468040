import React, { useEffect } from 'react'

import { useNav } from '@lib/hooks/index'

const Tutorials = ({ ...props }) => {
    const { setPage } = useNav()

    useEffect(() => {
        setPage('webapp')
    })


    return (
        <>
            Welcome to Tutorials of WebApp
        </>
    )
}

export default Tutorials
