import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, CircularProgress } from '@mui/material';
const BridgeErrorView = () => {
    const [open, setOpen] = useState(true);

    return (
        <Dialog open={open} onClose={() => { }} slotProps={{
            backdrop: {
                sx: {
                    background: 'rgba(126, 99, 182, 0.6)'
                }
            }
        }}>
            <DialogTitle sx={{ background: '#FFFFFF' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="x-headline3-black" sx={{ textAlign: 'center' }}>
                            Connection error
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent sx={{ background: '#F5F5F5' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        Error connecting to the robo bridge. Please check your connection and try again.
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center', pl: 4, pb: 2, pt: 2 }}>
                <Button
                    variant='contained'
                    onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BridgeErrorView;