import React from 'react'

import SoundIconButton from '@webapp/components/blocks/component/sound-icon-button'
import { SoundIconTypes } from '@components/Utils/SoundIconTypes'

const iconStyle = { width: '55px', height: '56px' }

export const getSoundWidgetList = () =>
  SoundIconTypes.map((iconType, index) => {
    const { type, icon: Icon } = iconType
    const uniqueIdCounter = `${type}_${index}`

    return {
      id: uniqueIdCounter,
      component: (
        <SoundIconButton width="100%" height="100%" soundType={type} key={index} type={type}>
          <Icon sx={iconStyle} />
        </SoundIconButton>
      ),
      width: 1,
      height: 1,
    }
  })
