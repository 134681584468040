import {
  BellSoundIcon,
  PartySoundIcon,
  CarSoundIcon,
  CatSoundIcon,
  ChickSoundIcon,
  ClockSoundIcon,
  DogSoundIcon,
  RobotSoundIcon,
  OwlSoundIcon,
  SpaceshipSoundIcon,
  DinosaurSoundIcon,
  HorseSoundIcon,
} from '@components/icons'

export const SoundIconTypes = [
  {
    type: 'Party',
    icon: PartySoundIcon,
  },
  {
    type: 'Bell',
    icon: BellSoundIcon,
  },
  {
    type: 'Car',
    icon: CarSoundIcon,
  },
  {
    type: 'Chick',
    icon: ChickSoundIcon,
  },
  {
    type: 'Clock',
    icon: ClockSoundIcon,
  },
  {
    type: 'Cat',
    icon: CatSoundIcon,
  },
  {
    type: 'Dog',
    icon: DogSoundIcon,
  },
  {
    type: 'Robot',
    icon: RobotSoundIcon,
  },
  {
    type: 'Owl',
    icon: OwlSoundIcon,
  },
  {
    type: 'Spaceship',
    icon: SpaceshipSoundIcon,
  },
  {
    type: 'Dinosaur',
    icon: DinosaurSoundIcon,
  },
  {
    type: 'Horse',
    icon: HorseSoundIcon,
  },
]
