import React, { useState, useEffect, useContext } from 'react'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors as themeColors } from '@themes/config/theme-colors'
import { DistanceArrowIcon } from '@components/icons'
import StyledBox from '@webapp/components/blocks/component/styled-box'
import StyledSlider from '@webapp/components/blocks/component/styled-slider'
import { EditorContext } from '@webapp/components/hoc/WithEditor'

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '8px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}))

const DistanceSensorControl = ({ widgetNumber, width, height }) => {
  const [value, setValue] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  const showplayButton = useContext(EditorContext)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  return (
    <StyledBox
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      color="#E31871"
      width={width}
      height={height}
    >
      {!!widgetNumber && <StyledTypography variant="x-lead-semibold">{widgetNumber}</StyledTypography>}

      <DistanceArrowIcon sx={{ width: '38px', height: '14px', marginBottom: '15px' }} />

      <StyledSlider
        orientation="vertical"
        min={0}
        max={40}
        value={value}
        onChange={handleSliderChange}
        disabled={showplayButton}
      />

      <Typography variant="x-body-semibold">{`${value} cm`} </Typography>
    </StyledBox>
  )
}

export default DistanceSensorControl
